import { Box, SimpleGrid, Flex } from "@chakra-ui/react";
import { ReportFooter } from "../components/report-footer";
import { ReportHeader } from "../components/report-header";

import { ReportTypography } from "../components/report-typography";
import {
  SortOrder,
  useListBuildingElementsQuery,
} from "../../../lib/generated/graphql";
import { ReportContainerHorizontal } from "../components/report-container-horizontal";

import { chunk } from "lodash";
import { ReportPageProps } from "../../report-page";
import { useMemo } from "react";
import { BEPreview } from "../../../lib/components/building-element/BEPreview";

export default function ReportBuildingElementCO2Page(props: ReportPageProps) {
  const chapter = "Resultat";
  const { projectId } = props;

  const { data } = useListBuildingElementsQuery({
    variables: {
      projectId,
      orderBy: [{ name: SortOrder.Asc }],
    },
  });

  const chunkedBuildingElements = useMemo(() => {
    const buildingElementTemplates = [
      ...(data?.project?.buildingElements || []),
    ]
      ?.filter((b) => b?.thickness !== 0)
      ?.filter((b) => (b?.products?.length ?? 0) > 0);

    return chunk(buildingElementTemplates, 4);
  }, [data?.project?.buildingElements]);

  return chunkedBuildingElements.map(([b1, b2, b3, b4]) => {
    return (
      <ReportContainerHorizontal key={b1.id}>
        <ReportHeader chapter={chapter} />
        <Flex flexDir="column" height="100%">
          <Box mt={5} mb={1} textAlign="left">
            <ReportTypography variant="h3">{"Byggdelar"}</ReportTypography>
          </Box>

          <SimpleGrid
            columns={2}
            gap={4}
            flexGrow={1}
            mt={16}
            justifyItems="center"
          >
            {b1 && <BEPreview buildingElement={b1} />}
            {b2 && <BEPreview buildingElement={b2} />}
            {b3 && <BEPreview buildingElement={b3} />}
            {b4 && <BEPreview buildingElement={b4} />}
          </SimpleGrid>
        </Flex>
        <ReportFooter pageNumber={props.pageNumber} skipPaddingTop />
      </ReportContainerHorizontal>
    );
  });
}
